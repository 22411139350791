var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"merge-fields-group-wrapper",class:{ 'mb-2': !_vm.marginBottomEmtpy }},[(!_vm.hideTitle)?_c('h6',{staticClass:"h6"},[_vm._v("Merge fields")]):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"mergefield-dropdown"},[_c('div',{staticClass:"mergefield-dropdown-element",class:{ 'open': _vm.open }},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":_vm.buttonVariant,"size":_vm.dropdownSize},on:{"click":_vm.toggleOpen}},[_vm._v("Personalization "),_c('i',{staticClass:"uil uil-angle-down"})]),_c('ul',[_c('li',{staticClass:"dropdown-item"},[_vm._m(0),_c('ul',[_c('li',{on:{"click":function($event){return _vm.appendField('[business_name]')}}},[_vm._m(1)]),_c('li',{on:{"click":function($event){return _vm.appendField('[business_phone]')}}},[_vm._m(2)]),_c('li',{on:{"click":function($event){return _vm.appendField('[business_website]')}}},[_vm._m(3)]),_c('li',{on:{"click":function($event){return _vm.appendField('[address]')}}},[_vm._m(4)]),_c('li',{on:{"click":function($event){return _vm.appendField('[city]')}}},[_vm._m(5)]),_c('li',{on:{"click":function($event){return _vm.appendField('[state]')}}},[_vm._m(6)]),_c('li',{on:{"click":function($event){return _vm.appendField('[zip]')}}},[_vm._m(7)]),_c('li',{on:{"click":function($event){return _vm.appendField('[country]')}}},[_vm._m(8)])])])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Business Info "),_c('i',{staticClass:"uil uil-angle-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"uil uil-copy"}),_vm._v(" Business Name")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"uil uil-copy"}),_vm._v(" Business Phone")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"uil uil-copy"}),_vm._v(" Business Website")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"uil uil-copy"}),_vm._v(" Address")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"uil uil-copy"}),_vm._v(" City")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"uil uil-copy"}),_vm._v(" State")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"uil uil-copy"}),_vm._v(" Zip")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"uil uil-copy"}),_vm._v(" Country")])
}]

export { render, staticRenderFns }